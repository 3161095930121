const ENTITIES = {
  AFFILIATE: 'Affiliate',
  AFFILIATE_PIXELS: 'Affiliatepixels',
  AFFILIATE_GROUPS: 'AffiliateGroups',
  OFFER_AFFILIATE_CAPPING: 'offer-affiliate-capping',
  SMARTLINK: 'Smartlink',
  SMARTLINK_PROMOTION: 'SmartlinkPromotion',
  OFFER: 'Offer',
  CATEGORY: 'Category',
  PAYOUT_TYPE: 'Payout-Type',
  ADVERTISER: 'Advertiser',
  USER: 'User',
  STATUS: 'Status',
  ROLE: 'Role',
  COUNTRY: 'Country',
  APPLICATION: 'Application',
  REFERRAL: 'Referral',
  COMMANDS: 'Commands',
  CURRENCY: 'Currency',
  FILTER_PRESET: 'filter-preset',
  CRYPTO_BLOCKCHAIN: 'Crypto-blockchain',
  CRYPTO_CURRENCIES: 'Crypto-currencies',
};

export default ENTITIES;
