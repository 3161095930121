<template>
  <v-autocomplete
    ref="autocomplete"
    :value="innerValue"
    v-model="vModel"
    :items="fetchedList"
    @input="input"
    @blur="isSearching = false"
    :search-input.sync="searchInput"
    :filter="customFilter"
    :item-value="itemValue"
    :item-text="itemText"
    :loading="loading"
    :required="required"
    :rules="rules"
    :label="label"
    :prepend-inner-icon="prependInnerIcon"
    :multiple="multiple"
    :solo="solo"
    :chips="chips"
    :dense="dense"
    :flat="flat"
    :solo-inverted="soloInverted"
    :clearable="clearable"
    :hide-details="hideDetails"
    :no-data-text="noDataText"
    :disabled="disabled"
    :return-object="returnObject"
  >
    <template v-slot:prepend-item>
      <div v-if="loading && isSearching">
        <v-progress-circular
          indeterminate
          width="2"
          size="20"
          color="grey"
          class="ml-5"
        ></v-progress-circular>
      </div>
    </template>
    <template v-slot:append-item>
      <div v-intersect="onIntersect">
        <v-progress-circular
          v-if="loading && !endOfScroll && !isSearching"
          indeterminate
          width="2"
          size="20"
          color="grey"
          class="ml-5"
        ></v-progress-circular>
      </div>
    </template>
    <template v-if="entitiesWithSelectionTemplate.includes(entity)" v-slot:selection="data">
      <v-chip
        v-if="ENTITIES_WITH_SELECTION_NAME_CHIP.includes(entity)"
        small
        label
        v-bind="data.attrs"
        class="ma-1"
        :input-value="data.selected"
        :color="computedChipColor ? getChipBgColor(CHIP_TYPE.SELECTION, data.item) : color"
        :text-color="
          computedChipColor ? getChipTextColor(CHIP_TYPE.SELECTION, data.item) : textColor
        "
        :close="clearableChips"
        @click:close="removeChip(data.item)"
      >
        {{ data.item.name }}
      </v-chip>
      <v-chip
        v-if="ENTITIES_WITH_SELECTION_EMAIL_CHIP.includes(entity)"
        small
        label
        v-bind="data.attrs"
        :input-value="data.selected"
        class="ma-1"
      >
        {{ data.item.email }}
      </v-chip>
    </template>
    <template v-if="entitiesWithItemTemplate.includes(entity)" v-slot:item="data">
      <v-chip
        v-if="ENTITIES_WITH_ITEM_NAME_CHIP.includes(entity)"
        small
        label
        v-bind="data.attrs"
        class="ma-1"
        :input-value="data.selected"
        :color="computedChipColor ? getChipBgColor(CHIP_TYPE.LIST_ITEM, data.item) : color"
        :text-color="
          computedChipColor ? getChipTextColor(CHIP_TYPE.LIST_ITEM, data.item) : textColor
        "
      >
        {{ data.item.name }}
      </v-chip>
      <v-chip
        v-if="ENTITIES_WITH_ITEM_EMAIL_CHIP.includes(entity)"
        small
        label
        v-bind="data.attrs"
        :input-value="data.selected"
        class="ma-1"
      >
        {{ data.item.email }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>
<script>
import _ from 'lodash';
import HttpClientV2 from '../../store/HttpClientV2';
import ENTITIES from '../../store/constants/Entities';
import { CHIP_TYPE, CUSTOM_CHIP_COLORS } from '../../store/constants/Chips';
import defaultValues from '../../store/constants/DefaultValues';
import RegexTypes from '../../store/constants/RegexTypes';

const ID_NAME_COLUMNS = '_id,name';
const ID_NAME_EMAIL_COLUMNS = '_id,name,email';
const ID_NAME_STATUS_COLUMNS = '_id,name,status';
const LIMIT = 20;
const DEBOUNCE_TIME = defaultValues.DEBOUNCE_TIME;
const ENTITIES_WITH_SELECTION_NAME_CHIP = [
  ENTITIES.USER,
  ENTITIES.CATEGORY,
  ENTITIES.PAYOUT_TYPE,
  ENTITIES.OFFER,
  ENTITIES.AFFILIATE,
  ENTITIES.SMARTLINK,
  ENTITIES.COUNTRY,
];
const ENTITIES_WITH_SELECTION_EMAIL_CHIP = [ENTITIES.USER];
const ENTITIES_WITH_ITEM_NAME_CHIP = [ENTITIES.USER];
const ENTITIES_WITH_ITEM_EMAIL_CHIP = [ENTITIES.USER];

export default {
  name: 'custom-autocomplete',
  data() {
    return {
      fetchedList: [],
      searchResults: [],
      loading: false,
      page: 0,
      innerValue: null,
      searchInput: null,
      isSearching: false,
      initialLoad: true,
      endOfScroll: false,
      hasSearched: false,
      ENTITIES,
      ENTITIES_WITH_SELECTION_NAME_CHIP,
      ENTITIES_WITH_SELECTION_EMAIL_CHIP,
      ENTITIES_WITH_ITEM_NAME_CHIP,
      ENTITIES_WITH_ITEM_EMAIL_CHIP,
      CHIP_TYPE,
      CUSTOM_CHIP_COLORS,
    };
  },
  props: {
    entity: {
      type: String,
      default: '',
    },
    value: {
      type: String | Array,
      default: null,
    },
    vModel: {
      type: String | Array,
      default: null,
    },
    initItems: {
      type: Object | Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: '_id',
    },
    itemText: {
      type: String | Function,
      default: 'name',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
      default: 'list',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    soloInverted: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    computedChipColor: {
      type: Boolean,
      default: false,
    },
    clearableChips: {
      type: Boolean,
      default: false,
    },
    customFilters: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async fetchList() {
      this.initialLoad = false;
      if (this.endOfScroll && !this.isSearching) {
        return;
      }
      const params = {
        sortBy: 'name',
        descending: false,
        limit: LIMIT,
        page: this.page,
        disablePagination: 'false',
        ...this.getDefaultParamsByEntity(),
        ...this.customFilters,
      };
      if (this.isSearching) {
        Object.assign(params, this.getSearchFilterCriteria(this.searchInput));
        params.page = 0;
        params.disablePagination = 'true';
      } else {
        if (this.page === 0) {
          this.setInitItems();
          if (this.fetchedList.length > 0) {
            params.limit = LIMIT - (this.fetchedList.length % LIMIT);
          }
        }
      }
      if (this.innerValue && this.innerValue.length > 0) {
        this.getNotIncludeValues(params);
      }
      this.loading = true;
      await HttpClientV2.callFunctionV2('GET', this.entity, params)
        .then((result) => {
          result.resultSet.forEach((item) => {
            const notInFetchedList = !this.fetchedList
              .map((item) => item[this.itemValue])
              .includes(item[this.itemValue]);
            if (notInFetchedList) {
              this.fetchedList.push(item);
            }
          });
          if (!this.isSearching) {
            this.endOfScroll = result.resultSet.length < params.limit;
          } else {
            this.hasSearched = true;
            this.searchResults = result.resultSet;
          }
        })
        .catch(() => {
          if (this.isSearching) {
            this.searchResults = [];
          }
          this.fetchedList = [];
        });
      this.loading = false;
      this.fetchedList.sort((a, b) => a.name.localeCompare(b.name));
    },
    getNotIncludeValues(params) {
      const idKey = this.entity === ENTITIES.COUNTRY ? 'code' : '_id';
      let notIncludeValues;
      if (Array.isArray(this.innerValue)) {
        notIncludeValues = this.returnObject
          ? this.innerValue.map((e) => e[idKey])
          : this.innerValue;
        notIncludeValues = notIncludeValues.join(',');
      } else {
        notIncludeValues = this.returnObject ? this.innerValue[idKey] : this.innerValue;
      }

      if (this.entity === ENTITIES.COUNTRY) {
        params.notIncludeCodes = notIncludeValues;
      } else {
        params.notIncludeIds = notIncludeValues;
      }
      return params;
    },
    getDefaultParamsByEntity() {
      const entityConfig = {
        [ENTITIES.OFFER]: {
          columns: ID_NAME_STATUS_COLUMNS,
          status: 'ACTIVE',
        },
        [ENTITIES.SMARTLINK]: {
          columns: ID_NAME_STATUS_COLUMNS,
          _applicationStatus: ['Approved'],
        },
        [ENTITIES.USER]: {
          columns: ID_NAME_EMAIL_COLUMNS,
          status: 'ACTIVE',
        },
        [ENTITIES.COUNTRY]: {},
      };
      return entityConfig[this.entity] || { columns: ID_NAME_COLUMNS };
    },
    getSearchFilterCriteria(searchInput) {
      return RegexTypes.ValidMongoDBObjectId.test(searchInput)
        ? { _id: searchInput }
        : { name: searchInput };
    },
    customFilter(item, queryText) {
      queryText = queryText.trim().replace(/\s+/g, ' ');
      const queryRegex = new RegExp(queryText, 'i');
      return RegexTypes.ValidMongoDBObjectId.test(queryText)
        ? item._id.search(queryRegex) >= 0
        : item.name.search(queryRegex) >= 0;
    },
    input(newValue) {
      this.innerValue = newValue ? newValue : null;
      this.$emit('input', this.innerValue);
    },
    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && !this.isSearching) {
        await this.fetchList();
        this.page++;
      }
    },
    setInitItems() {
      this.fetchedList = this.initItems ? [].concat(this.initItems) : [];
    },
    debouncedFetchList: _.debounce(async function () {
      await this.fetchList();
    }, DEBOUNCE_TIME),
    getChipBgColor(type, item) {
      if (!CUSTOM_CHIP_COLORS[this.entity] || !CUSTOM_CHIP_COLORS[this.entity][type]) {
        return this.color;
      }
      switch (this.entity) {
        case ENTITIES.OFFER:
          return CUSTOM_CHIP_COLORS[ENTITIES.OFFER][type].chip[item.status][
            this.$vuetify.theme.dark ? 'dark' : 'light'
          ];
        case ENTITIES.USER:
          return CUSTOM_CHIP_COLORS[ENTITIES.USER][type].chip;
        default:
          return this.color;
      }
    },
    getChipTextColor(type, item) {
      if (!CUSTOM_CHIP_COLORS[this.entity] || !CUSTOM_CHIP_COLORS[this.entity][type]) {
        return this.textColor;
      }
      switch (this.entity) {
        case ENTITIES.OFFER:
          return CUSTOM_CHIP_COLORS[ENTITIES.OFFER][type].text[item.status][
            this.$vuetify.theme.dark ? 'dark' : 'light'
          ];
        case ENTITIES.USER:
          return CUSTOM_CHIP_COLORS[ENTITIES.USER][type].text;
        default:
          return this.textColor;
      }
    },
    removeChip(item) {
      this.$emit('removeChip', item);
    },
    validate() {
      return this.$refs.autocomplete.validate();
    },
  },
  watch: {
    value: function (val) {
      this.searchInput = null;
      this.innerValue = val;
    },
    searchInput: {
      async handler(val) {
        if (this.initialLoad || _.isNil(val)) {
          return;
        }
        this.hasSearched = false;
        this.searchResults = [];
        this.isSearching = val?.length > 0;
        if (this.isSearching) {
          this.isLoading = true;
          await this.debouncedFetchList();
        } else {
          this.isLoading = false;
          await this.fetchList();
          this.page++;
        }
      },
    },
  },
  computed: {
    noDataText() {
      if (this.loading) {
        return '';
      } else if (this.isSearching) {
        return this.hasSearched && this.searchResults.length === 0 ? 'No data available' : '';
      } else if (this.endOfScroll) {
        return 'No data available';
      } else {
        return '';
      }
    },
    entitiesWithSelectionTemplate() {
      return [
        ...new Set([
          ...this.ENTITIES_WITH_SELECTION_EMAIL_CHIP,
          ...this.ENTITIES_WITH_SELECTION_NAME_CHIP,
        ]),
      ];
    },
    entitiesWithItemTemplate() {
      return [
        ...new Set([...this.ENTITIES_WITH_ITEM_EMAIL_CHIP, ...this.ENTITIES_WITH_ITEM_NAME_CHIP]),
      ];
    },
  },
  mounted() {
    if (this.value) {
      this.innerValue = this.value;
      this.vModel = this.value;
      this.setInitItems();
    }
  },
};
</script>
