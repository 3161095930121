<template>
  <div class="date-picker-container">
    <VueCtkDateTimePicker
      v-model="innerModel"
      :custom-shortcuts="shortcuts"
      format="YYYY-MM-DD"
      color="#42A5F5"
      @input="input($event)"
      range
      inline
      noKeyboard
      :dark="$vuetify.theme.dark"
      :max-date="maxDateSetup"
      :min-date="minDateSetup"
      style="z-index: 1"
      :first-day-of-week="1"
    />
    <small class="date-picker-label" v-if="hasGroupBySelection">
      <span class="mdi mdi-information text-h6"></span>
      <span v-if="RANGES_PER_GROUP_BY[groupBy] !== undefined">
        {{
          $t('commons.warning.restrictionRangesPerGroupBy', {
            groupBy: groupBy,
            rangeAmount: RANGES_PER_GROUP_BY[groupBy][0],
            rangeUnit: RANGES_PER_GROUP_BY[groupBy][1],
          })
        }}
      </span>
      <span v-else>
        {{
          $t('commons.warning.noRestrictionRangesPerGroupBy', {
            groupBy: groupBy,
          })
        }}
      </span>
    </small>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

const RANGES_PER_GROUP_BY = {
  month: [24, 'months'],
  week: [90, 'days'],
  day: [90, 'days'],
  hour: [3, 'days'],
  minute: [2, 'days'],
};

export default {
  name: 'date-range',
  components: {
    VueCtkDateTimePicker,
  },
  prevStartDate: moment().startOf('day').tz('GMT', true).toISOString(),
  prevEndDate: moment().endOf('day').tz('GMT', true).toISOString(),
  data: () => ({
    RANGES_PER_GROUP_BY,
    adjustEndDateOnShorterRange: false,
    innerModel: {
      start: moment().startOf('day').tz('GMT', true).toISOString(),
      end: moment().endOf('day').tz('GMT', true).toISOString(),
    },
    standardShortcuts: [
      { key: 'yesterday', label: 'Yesterday', value: '-day' },
      { key: 'today', label: 'Today', value: 'day' },
      {
        key: 'thisWeek',
        label: 'This week',
        value: () => {
          const thisWeekStart = moment().startOf('isoWeek');
          const thisWeekEnd = moment().endOf('day').isBefore(moment().endOf('isoWeek'))
            ? moment().endOf('day')
            : moment().endOf('isoWeek');
          return {
            start: thisWeekStart,
            end: thisWeekEnd,
          };
        },
      },
      {
        key: 'lastWeek',
        label: 'Last week',
        value: () => {
          const lastWeekStart = moment().startOf('isoWeek').subtract(1, 'week');
          const lastWeekEnd = moment().endOf('isoWeek').subtract(1, 'week');
          return {
            start: lastWeekStart,
            end: lastWeekEnd,
          };
        },
      },
      {
        key: 'last7Days',
        label: 'Last 7 days',
        value: () => {
          return {
            start: moment().subtract(7, 'days'),
            end: moment().subtract(1, 'days'),
          };
        },
      },
      {
        key: 'last30Days',
        label: 'Last 30 days',
        value: () => {
          return {
            start: moment().subtract(30, 'days'),
            end: moment().subtract(1, 'days'),
          };
        },
      },
      {
        key: 'thisMonth',
        label: 'This month',
        value: () => {
          const thisMonthStart = moment().startOf('month');
          const thisMonthEnd = moment().endOf('day').isBefore(moment().endOf('month'))
            ? moment().endOf('day')
            : moment().endOf('month');
          return {
            start: thisMonthStart,
            end: thisMonthEnd,
          };
        },
      },
      { key: 'lastMonth', label: 'Last month', value: '-month' },
    ],
  }),
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        fromDate: moment().startOf('day').tz('GMT', true).toISOString(),
        toDate: moment().endOf('day').tz('GMT', true).toISOString(),
      }),
    },
    groupBy: {
      type: String,
      required: false,
    },
    hasMinDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    minDateMoment: {
      type: moment | null,
      required: false,
      default: null,
    },
    hasRangeLimitations: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxDateRange: {
      type: Array | null, // first element in the array is a number, the second is the unit (e.g., [60, 'days'])
      required: false,
      default: null,
    },
    hasGroupByRestriction: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasGroupBySelection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    maxDateSetup() {
      const startDate = this.innerModel.start;
      const endDate = this.innerModel.end;
      const endOfToday = moment().endOf('day');

      if ((!this.hasRangeLimitations && !this.maxDateRange) || endDate) {
        return endOfToday.format('YYYY-MM-DD');
      }

      let rangeLimit;
      const rangeByGroupBy = RANGES_PER_GROUP_BY[this.groupBy];

      if (this.maxDateRange?.length > 0) {
        rangeLimit = moment(startDate)
          .add(this.maxDateRange[0], this.maxDateRange[1])
          .endOf('day')
          .subtract(1, 'day');
      } else if (rangeByGroupBy) {
        rangeLimit = moment(startDate)
          .add(rangeByGroupBy[0], rangeByGroupBy[1])
          .endOf('day')
          .subtract(1, 'day');
      } else {
        rangeLimit = endOfToday;
      }

      const maxDate = startDate && rangeLimit.isBefore(endOfToday) ? rangeLimit : endOfToday;
      return maxDate.format('YYYY-MM-DD');
    },
    minDateSetup() {
      if (!this.hasMinDate) {
        return null;
      }
      const minDate = this.minDateMoment ? this.minDateMoment : moment('2020-01-01');
      return minDate.format('YYYY-MM-DD');
    },
    shortcuts() {
      if (this.groupBy === 'hour' || this.groupBy === 'minute') {
        return [
          { key: 'yesterday', label: 'Yesterday', value: '-day' },
          { key: 'today', label: 'Today', value: 'day' },
        ];
      }

      return this.standardShortcuts;
    },
  },
  methods: {
    input(value) {
      if (!value) {
        return;
      }

      const groupByUnit =
        {
          week: 'isoWeek',
          month: 'month',
          year: 'year',
        }[this.groupBy] || 'day';
      const periodLimitation = this.hasGroupByRestriction ? groupByUnit : 'day';
      const startMoment = moment(value.start).startOf(periodLimitation);
      let endMoment = null;

      if (value.end) {
        endMoment = moment(value.end).endOf(periodLimitation);
        value.end = endMoment.isAfter(moment()) ? moment() : endMoment;
      }

      this.innerModel = {
        start: startMoment.tz('GMT', true).toISOString(),
        end: endMoment ? endMoment.tz('GMT', true).endOf('day').toISOString() : null,
      };
    },
    async setMaxDateOnNull() {
      if (!this.innerModel.end) {
        this.innerModel = {
          ...this.innerModel,
          end: moment(this.innerModel.start).endOf('day').tz('GMT', true).toISOString(),
        };
      }
    },
  },
  watch: {
    innerModel(newValue, oldValue) {
      this.prevStartDate = oldValue.start;
      this.prevEndDate = oldValue.end;
      let result = {
        fromDate: newValue.start,
        toDate: newValue.end,
      };
      this.$emit('input', result);
    },
    groupBy(newValue, oldValue) {
      const rangeAmount = (value) => RANGES_PER_GROUP_BY[value]?.[0];
      const rangeUnit = (value) => RANGES_PER_GROUP_BY[value]?.[1];
      this.adjustEndDateOnShorterRange =
        rangeUnit(oldValue) === undefined ||
        (rangeUnit(oldValue) === 'months' && rangeUnit(newValue) === 'days') ||
        rangeAmount(newValue) < rangeAmount(oldValue);

      if (!this.adjustEndDateOnShorterRange) return;

      const startDate = moment(this.innerModel.start);
      const currentEndDate = moment(this.innerModel.end);
      const maxEndDate = moment(startDate)
        .add(rangeUnit(newValue), rangeAmount(newValue))
        .endOf('day')
        .subtract(1, 'day');

      if (maxEndDate.isBefore(currentEndDate)) {
        this.innerModel = {
          ...this.innerModel,
          end: maxEndDate.endOf('day').tz('GMT', true).toISOString(),
        };
      }
    },
  },
  mounted() {
    this.innerModel = {
      start: moment().startOf('day').tz('GMT', true).toISOString(),
      end: moment().endOf('day').tz('GMT', true).toISOString(),
    };
  },
};
</script>
<style lang="scss">
.shortcuts-container {
  height: 100% !important;
}
.date-picker-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 0.5em;

  .date-picker-label {
    margin: 0 1em;
    font-weight: 300;

    span.mdi {
      color: #42a5f5;
    }
  }
}
</style>
